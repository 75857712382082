import { TeamPermission } from './graphql/generated/types.generated';

export const PATHS_NO_SUBSCRIPTION_REQUIRED = ['/settings', '/subscribe'];

export const TEAM_RESTRICTED_ROUTES = [
    {
        route: /\/settings\/workspace\/billing/,
        permissions: [TeamPermission.Billing],
    },
    {
        route: /\/settings\/workspace\/general/,
        permissions: [TeamPermission.Owner],
    },
    {
        route: /\/dashboards\/create.*/,
        permissions: [TeamPermission.ManageInsights],
    },
    {
        route: /\/dashboards\/(.*)\/edit/,
        permissions: [TeamPermission.ManageInsights],
    },
    {
        route: /\/dashboards\/(.*)\/widgets\/create.*/,
        permissions: [TeamPermission.ManageInsights],
    },
    {
        route: /\/dashboards\/(.*)\/widgets\/(.*)\/edit/,
        permissions: [TeamPermission.ManageInsights],
    },
    {
        route: /\/dashboards.*/,
        permissions: [TeamPermission.ViewInsights],
    },
];

export const COOKIES = {
    MAIN_NAV_OPENED: 'main-nav-opened',
};

export const FEATURE_FLAGS = {
    INSIGHTS_BETA: 'fenixInsightsBeta',
};

export const LOCAL_STORAGE = {
    RECENT_DASHBOARDS_WORKSPACE: 'RECENT_DASHBOARDS_WORKSPACE',
};

export const HELP_RESOURCES_ARTICLES = {
    CREATE_DASHBOARD: 41139,
};

import { TeamPermissionsProvider } from '@/providers/team-permissions-provider';
import { Outlet } from 'react-router-dom';

export default function PublicLayout() {
    return (
        <>
            {/* TeamPermissionsProvider is here because of PublicView, some components use this provider (so is required to exist even tho that has no team permissions) */}
            <TeamPermissionsProvider initialValue={[]}>
                <Outlet />
            </TeamPermissionsProvider>
        </>
    );
}

import { NavigationProvider } from '../providers/navigation-provider';
import MainNav from '@/components/nav/MainNav';
import { SessionProvider } from '../providers/session-provider';
import { useQuery } from '@apollo/client';
import { PaymentProvider } from '../providers/payment-provider';
import SubscriptionAccessControl from '@/components/SubscriptionAccessControl';
import { TeamPermissionsProvider } from '../providers/team-permissions-provider';
import { Outlet } from 'react-router-dom';
import LegacyIframes from '@/legacy/components/LegacyIframes';
import InitialLoading from './InitialLoading';
import { AppInitialLoadDocument } from '@/graphql/generated/app-initial-load.generated';
import { Suspense } from 'react';
import PageLayoutLoading from './PageLayoutLoading';
import { apolloIsKnownError } from '@/utils/apollo-is-known-error';
import { HelpResourcesProvider } from '../providers/help-resources-provider';
import { SocketProvider } from '@/app/providers/socket-provider';
import NotificationsEmitControl from '@/components/notifications/NotificationsEmitControl';

export default function PrivateLayout() {
    const { loading, data, error } = useQuery(AppInitialLoadDocument);
    if (loading) {
        return <InitialLoading />;
    }
    if (error && !apolloIsKnownError(error)) {
        throw error;
    }
    if (!data) {
        return null;
    }

    return (
        <>
            <SessionProvider initialValue={data}>
                <HelpResourcesProvider>
                    <TeamPermissionsProvider initialValue={data.teamPermissions}>
                        <PaymentProvider>
                            <NavigationProvider>
                                <SocketProvider>
                                    <MainNav />
                                    <Suspense fallback={<PageLayoutLoading />}>
                                        <Outlet />
                                    </Suspense>
                                    <LegacyIframes />
                                    <NotificationsEmitControl />
                                </SocketProvider>
                            </NavigationProvider>
                        </PaymentProvider>
                    </TeamPermissionsProvider>
                </HelpResourcesProvider>
            </SessionProvider>
            <SubscriptionAccessControl hasActiveSubscription={data.hasActiveSubscription} />
        </>
    );
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import colors from './colors-palette';
import { ThemeOptions, CssVarsThemeOptions } from '@mui/material/styles';

// TODO - Temporary ts ignore due to incompatibility with Figma MUI

// transform normal link into react router link (https://mui.com/material-ui/integrations/routing/#global-theme-link)
const LinkBehavior = React.forwardRef<
    HTMLAnchorElement,
    Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
    const { href, ...other } = props;

    if (href) {
        // only use Link if href is present
        // map href (Material UI) -> to (react-router)
        return <RouterLink ref={ref} to={href} {...other} />;
    } else {
        // without href use default div
        return <div {...other} />;
    }
});

const theme: Omit<ThemeOptions, 'components'> &
    Pick<CssVarsThemeOptions, 'defaultColorScheme' | 'colorSchemes' | 'components'> & {
        cssVariables?:
            | boolean
            | Pick<
                  CssVarsThemeOptions,
                  | 'colorSchemeSelector'
                  | 'rootSelector'
                  | 'disableCssColorScheme'
                  | 'cssVarPrefix'
                  | 'shouldSkipGeneratingVar'
              >;
    } = {
    cssVariables: {
        colorSchemeSelector: 'class',
    },
    colorSchemes: {
        light: {
            palette: {
                text: {
                    primary: '#1B1B1F',
                    secondary: '#46464F',
                    disabled: 'rgba(27, 27, 31, 0.38)',
                    _states: {
                        hover: 'rgba(27, 27, 31, 0.04)',
                        selected: 'rgba(27, 27, 31, 0.08)',
                        focus: 'rgba(27, 27, 31, 0.12)',
                        focusVisible: 'rgba(27, 27, 31, 0.3)',
                    },
                    inverse: '#F3F0F4',
                },
                primary: {
                    main: '#283FFF',
                    dark: '#0020DD',
                    light: '#7886FF',
                    blue: '#FBF8FF',
                    _states: {
                        hover: 'rgba(40, 63, 255, 0.04)',
                        selected: 'rgba(40, 63, 255, 0.08)',
                        focus: 'rgba(40, 63, 255, 0.12)',
                        focusVisible: 'rgba(40, 63, 255, 0.3)',
                        outlinedBorder: 'rgba(40, 63, 255, 0.5)',
                    },
                },
                secondary: {
                    main: '#5056A9',
                    dark: '#383E8F',
                    light: '#8389E0',
                    _states: {
                        hover: 'rgba(80, 86, 169, 0.04)',
                        selected: 'rgba(80, 86, 169, 0.08)',
                        focus: 'rgba(80, 86, 169, 0.12)',
                        focusVisible: 'rgba(80, 86, 169, 0.3)',
                        outlinedBorder: 'rgba(80, 86, 169, 0.5)',
                    },
                },
                error: {
                    main: '#BA1A1A',
                    dark: '#93000A',
                    light: '#FF5449',
                    _states: {
                        hover: 'rgba(186, 26, 26, 0.04)',
                        selected: 'rgba(186, 26, 26, 0.08)',
                        focusVisible: 'rgba(186, 26, 26, 0.3)',
                        outlinedBorder: 'rgba(186, 26, 26, 0.5)',
                    },
                },
                warning: {
                    main: '#C2571C',
                    dark: '#A23F00',
                    light: '#EC723D',
                    _states: {
                        hover: 'rgba(194, 87, 28, 0.04)',
                        selected: 'rgba(194, 87, 28, 0.08)',
                        focusVisible: 'rgba(194, 87, 28, 0.3)',
                        outlinedBorder: 'rgba(194, 87, 28, 0.5)',
                    },
                },
                info: {
                    main: '#00658B',
                    dark: '#004C6A',
                    light: '#019BD2',
                    _states: {
                        hover: 'rgba(0, 101, 139, 0.04)',
                        selected: 'rgba(0, 101, 139, 0.08)',
                        focusVisible: 'rgba(0, 101, 139, 0.3)',
                        outlinedBorder: 'rgba(0, 101, 139, 0.5)',
                    },
                },
                success: {
                    main: '#006C48',
                    dark: '#005235',
                    light: '#6CDBA6',
                    _states: {
                        hover: 'rgba(0, 108, 72, 0.04)',
                        selected: 'rgba(0, 108, 72, 0.08)',
                        focusVisible: 'rgba(0, 108, 72, 0.3)',
                        outlinedBorder: 'rgba(0, 108, 72, 0.5)',
                    },
                },
                background: {
                    'paper-elevation-0': '#ECEDFB',
                    'paper-elevation-1': '#F8F9FF',
                    'paper-elevation-4': '#F3F4FE',
                    'paper-elevation-5': '#F3F4FE',
                    'paper-elevation-6': '#F3F4FE',
                    'paper-elevation-7': '#EEF0FD',
                    'paper-elevation-8': '#EEF0FD',
                    'paper-elevation-9': '#EEF0FD',
                    'paper-elevation-10': '#ECEFFE',
                    'paper-elevation-11': '#ECEFFE',
                    'paper-elevation-12': '#ECEFFE',
                    'paper-elevation-13': '#E9ECFD',
                    'paper-elevation-14': '#E9ECFD',
                    'paper-elevation-15': '#E9ECFD',
                    'paper-elevation-16': '#E4E8FD',
                    'paper-elevation-17': '#E4E8FD',
                    'paper-elevation-18': '#E2E6FC',
                    'paper-elevation-19': '#E2E6FC',
                    'paper-elevation-20': '#DDE2FC',
                    'paper-elevation-21': '#DDE2FC',
                    'paper-elevation-22': '#DBE0FC',
                    'paper-elevation-23': '#DBE0FC',
                    'paper-elevation-24': '#D8DDFB',
                },
                elevation: {
                    outlined: '#78767A',
                    'outline-variant': '#C8C5CA',
                },
                _components: {
                    avatar: {
                        fill: '#0020DD',
                    },
                    input: {
                        outlined: {
                            enabledBorder: '#C8C5CA',
                            hoverBorder: '#78767A',
                        },
                    },
                    switch: {
                        knobFillEnabled: '#F3F0F4',
                        knowFillDisabled: '#E4E1E6',
                    },
                    snackbar: {
                        fill: '#1A1B23',
                    },
                    chip: {
                        defaultEnabledBorder: '#78767A',
                        defaultFocusFill: 'rgba(0, 0, 0, 0.18)',
                    },
                    tooltip: {
                        fill: '#303034',
                        contrast: '#F3F0F4',
                    },
                    backdrop: {
                        fill: 'rgba(0, 0, 0, 0.16)',
                    },
                    appBar: {
                        defaultFill: '#F3F4FE',
                    },
                    breadcrumbs: {
                        collapseFill: '#E4E1E6',
                    },
                    alert: {
                        error: {
                            color: '#410002',
                            background: '#FFDAD6',
                        },
                        warning: {
                            color: '#351000',
                            background: '#FFDBCC',
                        },
                        info: {
                            color: '#001E2D',
                            background: '#C5E7FF',
                        },
                        success: {
                            color: '#002113',
                            background: '#89F8C1',
                        },
                    },
                    stepper: {
                        connector: '#78767A',
                    },
                    rating: {
                        activeFill: '#FFB400',
                    },
                },
                _native: {
                    'scrollbar-bg': '#C8C5CA',
                },
            },
        },
        dark: {
            palette: {
                text: {
                    primary: '#E4E1E6',
                    secondary: '#C7C5D0',
                    disabled: 'rgba(248, 253, 255, 0.38)',
                    _states: {
                        hover: 'rgba(227, 225, 236, 0.08)',
                        selected: 'rgba(227, 225, 236, 0.16)',
                        focus: 'rgba(227, 225, 236, 0.12)',
                        focusVisible: 'rgba(227, 225, 236, 0.3)',
                    },
                    inverse: '#1B1B1F',
                },
                primary: {
                    main: '#BDC2FF',
                    dark: '#9AA4FF',
                    light: '#DFE0FF',
                    contrast: '#00149E',
                    _states: {
                        hover: 'rgba(189, 194, 255, 0.08)',
                        selected: 'rgba(189, 194, 255, 0.16)',
                        focus: 'rgba(189, 194, 255, 0.12)',
                        focusVisible: 'rgba(189, 194, 255, 0.3)',
                        outlinedBorder: 'rgba(189, 194, 255, 0.5)',
                    },
                },
                secondary: {
                    main: '#BEC2FF',
                    dark: '#9EA4FD',
                    light: '#E0E0FF',
                    contrast: '#202578',
                    _states: {
                        hover: 'rgba(190, 194, 255, 0.08)',
                        selected: 'rgba(190, 194, 255, 0.16)',
                        focus: 'rgba(190, 194, 255, 0.12)',
                        focusVisible: 'rgba(190, 194, 255, 0.3)',
                        outlinedBorder: 'rgba(190, 194, 255, 0.5)',
                    },
                },
                error: {
                    main: '#FFB4AB',
                    dark: '#FF897D',
                    light: '#FFDAD6',
                    contrast: '#690005',
                    _states: {
                        hover: 'rgba(255, 180, 171, 0.08)',
                        selected: 'rgba(255, 180, 171, 0.16)',
                        focusVisible: 'rgba(255, 180, 171, 0.3)',
                        outlinedBorder: 'rgba(255, 180, 171, 0.5)',
                    },
                },
                warning: {
                    main: '#FFB695',
                    dark: '#FF8C54',
                    light: '#FFDBCC',
                    contrast: '#571F00',
                    _states: {
                        hover: 'rgba(255, 182, 149, 0.08)',
                        selected: 'rgba(255, 182, 149, 0.16)',
                        focusVisible: 'rgba(255, 182, 149, 0.3)',
                        outlinedBorder: 'rgba(255, 182, 149, 0.5)',
                    },
                },
                info: {
                    main: '#7ED0FF',
                    dark: '#3FB6EF',
                    light: '#C5E7FF',
                    contrast: '#00344A',
                    _states: {
                        hover: 'rgba(126, 208, 255, 0.08)',
                        selected: 'rgba(126, 208, 255, 0.16)',
                        focusVisible: 'rgba(126, 208, 255, 0.3)',
                        outlinedBorder: 'rgba(126, 208, 255, 0.5)',
                    },
                },
                success: {
                    main: '#6CDBA6',
                    dark: '#4FBF8C',
                    light: '#E8FFEF',
                    contrast: '#003824',
                    _states: {
                        hover: 'rgba(108, 219, 166, 0.08)',
                        selected: 'rgba(108, 219, 166, 0.16)',
                        focusVisible: 'rgba(108, 219, 166, 0.3)',
                        outlinedBorder: 'rgba(108, 219, 166, 0.5)',
                    },
                },
                background: {
                    default: '#212121',
                    'paper-elevation-0': '#212121',
                    'paper-elevation-1': '#28292C',
                    'paper-elevation-2': '#2C2D31',
                    'paper-elevation-3': '#2D2E32',
                    'paper-elevation-4': '#2F2F35',
                    'paper-elevation-5': '#323239',
                    'paper-elevation-6': '#323239',
                    'paper-elevation-7': '#323239',
                    'paper-elevation-8': '#34343C',
                    'paper-elevation-9': '#34343C',
                    'paper-elevation-10': '#34343C',
                    'paper-elevation-11': '#34343C',
                    'paper-elevation-12': '#363740',
                    'paper-elevation-13': '#363740',
                    'paper-elevation-14': '#363740',
                    'paper-elevation-15': '#363740',
                    'paper-elevation-16': '#383942',
                    'paper-elevation-17': '#383942',
                    'paper-elevation-18': '#383942',
                    'paper-elevation-19': '#383942',
                    'paper-elevation-20': '#383942',
                    'paper-elevation-21': '#383942',
                    'paper-elevation-22': '#383942',
                    'paper-elevation-23': '#383942',
                    'paper-elevation-24': '#3A3B45',
                },
                elevation: {
                    outlined: '#929094',
                    'outline-variant': '#47464A',
                },
                _components: {
                    avatar: {
                        fill: '#DFE0FF',
                    },
                    input: {
                        outlined: {
                            enabledBorder: '#C8C5CA',
                            hoverBorder: '#78767A',
                        },
                    },
                    switch: {
                        knobFillEnabled: '#ACAAAF',
                        knowFillDisabled: '#5F5E62',
                    },
                    snackbar: {
                        fill: '#FFFBFF',
                    },
                    chip: {
                        defaultEnabledBorder: '#78767A',
                        defaultFocusFill: 'rgba(255, 255, 255, 0.18)',
                    },
                    tooltip: {
                        fill: '#C8C5CA',
                        contrast: '#F3F0F4',
                    },
                    backdrop: {
                        fill: 'rgba(0, 0, 0, 0.16)',
                    },
                    appBar: {
                        defaultFill: '#F3F4FE',
                    },
                    breadcrumbs: {
                        collapseFill: '#5F5E62',
                    },
                    alert: {
                        error: {
                            color: '#FFDAD6',
                            background: '#93000A',
                        },
                        warning: {
                            color: '#FFDBCC',
                            background: '#7B2F00',
                        },
                        info: {
                            color: '#C5E7FF',
                            background: '#004C6A',
                        },
                        success: {
                            color: '#89F8C1',
                            background: '#005235',
                        },
                    },
                    stepper: {
                        connector: '#78767A',
                    },
                    rating: {
                        activeFill: '#FFB400',
                    },
                },
                _native: {
                    'scrollbar-bg': '#5F5E62',
                },
            },
        },
    },
    typography: {
        //fontFamily: "'Roboto Flex', sans-serif",
        h1: {
            fontSize: '3.5625rem',
            fontFamily: "'Roboto Flex', sans-serif",
            fontWeight: 400,
            lineHeight: '112.28%',
        },
        h2: {
            fontSize: '2.25rem',
            fontFamily: "'Roboto Flex', sans-serif",
            fontWeight: 400,
            lineHeight: '122.22%',
        },
        h3: {
            fontSize: '2rem',
            fontFamily: "'Roboto Flex', sans-serif",
            lineHeight: '125%',
        },
        h4: {
            fontSize: '1.5rem',
            fontFamily: "'Roboto Flex', sans-serif",
            lineHeight: '133.33%',
        },
        h5: {
            fontSize: '1.375rem',
            fontFamily: "'Roboto Flex', sans-serif",
            lineHeight: '127.3%',
        },
        h6: {
            fontSize: '1rem',
            fontFamily: "'Roboto Flex', sans-serif",
            lineHeight: '150%',
        },
        body1: {
            fontFamily: "'Roboto Flex', sans-serif",
        },
        body2: {
            fontFamily: "'Roboto Flex', sans-serif",
            lineHeight: '142.86%',
        },
        subtitle1: {
            fontFamily: "'Roboto Flex', sans-serif",
            fontWeight: 500,
            lineHeight: '150%',
        },
        subtitle2: {
            fontFamily: "'Roboto Flex', sans-serif",
            lineHeight: '142.86%',
        },
        overline: {
            fontFamily: "'Roboto Flex', sans-serif",
            fontWeight: 600,
            lineHeight: '133.33%',
        },
        caption: {
            fontFamily: "'Roboto Flex', sans-serif",
            fontWeight: 500,
            lineHeight: '133.3%',
        },
    },
    shape: {
        xs: 4,
        lg: 16,
        md: 12,
        sm: 8,
        full: 999,
    },
    components: {
        MuiLink: {
            defaultProps: {
                component: LinkBehavior,
            },
        },
        MuiListItemButton: {
            defaultProps: {
                component: LinkBehavior,
            },
        },
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    fontFamily: "'Roboto Flex', sans-serif",
                    borderRadius: 'var(--mui-shape-full)',
                    boxShadow: 'none',
                    textTransform: 'none',
                    ...(ownerState.size === 'large' && {
                        fontSize: '0.875rem',
                        lineHeight: '142.9% /* 20.006px */',
                        letterSpacing: '0.1px',
                    }),
                    ...(ownerState.size === 'medium' && {
                        fontSize: '0.75rem',
                        lineHeight: '133.333% /* 16px */',
                        letterSpacing: '0.5px',
                    }),
                    ...(ownerState.size === 'small' && {
                        fontSize: '0.6875rem',
                        lineHeight: '145.45% /* 15.999px */',
                        letterSpacing: '0.5px',
                    }),
                    ':hover': {
                        boxShadow: 'none',
                    },
                }),
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: 16,
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    borderRadius: 16,
                },
                message: {
                    flex: 1,
                },
            },
        },
        MuiSnackbarContent: {
            styleOverrides: {
                root: {
                    padding: 0,
                    width: 406,
                    backgroundColor: 'white',
                    borderRadius: 8,
                },
                message: {
                    padding: 0,
                    position: 'relative',
                    flex: 1,
                    borderRadius: 8,
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                label: {
                    fontFamily: "'Roboto Flex', sans-serif",
                },
                colorPrimary: {
                    color: colors.blue[600],
                    backgroundColor: colors.blue[200],
                },
                colorSecondary: {
                    color: colors.indigo[600],
                    backgroundColor: colors.indigo[200],
                },
                colorSuccess: {
                    color: colors.green[600],
                    backgroundColor: colors.green[200],
                },
                colorInfo: {
                    color: colors.lightBlue[600],
                    backgroundColor: colors.lightBlue[200],
                },
                colorWarning: {
                    color: colors.orange[600],
                    backgroundColor: colors.orange[200],
                },
                colorError: {
                    color: colors.red[600],
                    backgroundColor: colors.red[200],
                },
                root: {
                    borderRadius: 4,
                },
            },
        },
        MuiCard: {
            defaultProps: { variant: 'outlined' },
            styleOverrides: {
                root: {
                    borderRadius: 16,
                    borderColor: 'var(--mui-palette-elevation-outline-variant)',
                },
            },
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    borderRadius: 12,
                    padding: '0.75rem 1rem 0.5rem 1rem',
                },
            },
        },
        MuiIcon: {
            styleOverrides: {
                root: {
                    color: '#1A1B25',
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: '#1A1B25',
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: '#1A1B25',
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                dense: {
                    minHeight: 40,
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    fontFamily: "'Roboto Flex', sans-serif",
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    borderRadius: 4,
                    padding: 0,
                },
            },
        },
    },
};

export default theme;

import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

export const POSTHOG_ENABLED = import.meta.env.VITE_POSTHOG_KEY.length > 5;

if (typeof window !== 'undefined') {
    posthog.init(import.meta.env.VITE_POSTHOG_KEY!, {
        api_host: import.meta.env.VITE_POSTHOG_HOST,
        capture_pageview: false, // Disable automatic pageview capture, as we capture manually
        autocapture: {
            element_allowlist: ['button', 'a'],
            css_selector_allowlist: ['[ph]', 'button[data-id]', 'a[data-id]'],
        },
        opt_in_site_apps: true,
        capture_pageleave: false,
    });
}

export function PHProvider({ children }: { children: React.ReactNode }) {
    return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}

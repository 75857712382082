import { useEffect } from 'react';
import {
    Box,
    Drawer as MuiDrawer,
    Grid2 as Grid,
    useMediaQuery,
    BoxProps,
    IconButton,
    Theme,
    CSSObject,
    Tooltip,
} from '@mui/material';
import MenuOpenRounded from '@mui/icons-material/MenuOpenRounded';
import MainNavMenu from './MainNavMenu';
import { useNavigation } from '@/providers/navigation-provider';
import { useQuery } from '@apollo/client';
import { MainNavDocument } from '@/graphql/generated/main-nav.generated';
import { useTheme, styled } from '@mui/material/styles';
import Cookies from 'js-cookie';
import { COOKIES } from '@/constants';
import { Link } from 'react-router-dom';

export const drawerWidth = 256;
export const miniDrawerWidth = 57;
export const submenuWidth = 212;

const DrawerHeader = styled('div', { shouldForwardProp: (prop) => prop !== 'open' })<{ open?: boolean }>(
    ({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    }),
);

const openedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (): CSSObject => ({
    overflowX: 'hidden',
    width: miniDrawerWidth,
});

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open' && prop !== 'openAbsolute',
})<{
    openAbsolute?: boolean;
}>(({ theme, open, openAbsolute }) => ({
    width: openAbsolute ? miniDrawerWidth : drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': {
            width: drawerWidth,
            padding: theme.spacing(1),
            ...openedMixin(theme),
        },
    }),
    ...(!open && {
        ...closedMixin(),
        '& .MuiDrawer-paper': closedMixin(),
    }),
    '&.MuiDrawer-root': {
        zIndex: theme.zIndex.drawer + 1,
    },
}));

const SwonkieLogo = styled('img')<BoxProps>(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

const SwonkieLettering = styled('img')<BoxProps>(({ theme }) => ({
    marginLeft: '1rem',
    [theme.breakpoints.down('sm')]: {
        marginLeft: '2rem',
    },
}));

const CloseMenuMobile = styled(IconButton)(({ theme }) => ({
    padding: 0,
    [theme.breakpoints.up('sm')]: {
        display: 'none',
    },
}));

export default function MainNav() {
    const { mainNavOpen, setMainNavOpen, mainNavOpenAbsolute } = useNavigation();
    const cookieOpened = Cookies.get(COOKIES.MAIN_NAV_OPENED);

    const theme = useTheme();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const isLargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

    const { data } = useQuery(MainNavDocument, { notifyOnNetworkStatusChange: true });

    useEffect(() => {
        setMainNavOpen(isLargeScreen ? false : cookieOpened !== undefined ? cookieOpened === '1' : false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLargeScreen]);

    const mainNav = () => (
        <>
            <DrawerHeader>
                <Link to="/home">
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems={'center'}
                        display={mainNavOpen ? 'flex' : 'none'}
                        minWidth={`calc(${drawerWidth}px - 2rem)`}
                    >
                        <Grid display={'flex'} alignItems={'center'}>
                            <SwonkieLogo src="/swonkie-logo.svg" alt="Swonkie Logo" width={40} />
                            <CloseMenuMobile onClick={() => setMainNavOpen(false)} sx={{ p: 0 }}>
                                <MenuOpenRounded />
                            </CloseMenuMobile>
                            <SwonkieLettering src="/swonkie-lettering.svg" alt="Swonkie Logo" height={20} />
                        </Grid>
                    </Grid>

                    <Box display={!mainNavOpen ? 'flex' : 'none'} flexGrow={1} justifyContent="center">
                        <Tooltip title="Swonkie" arrow placement="right">
                            <SwonkieLogo src="/swonkie-logo.svg" alt="Swonkie Logo" width={40} />
                        </Tooltip>
                    </Box>
                </Link>
            </DrawerHeader>

            <MainNavMenu
                currentWorkspace={data?.currentWorkspace}
                currentSubscription={data?.subscriptionPreview}
            />
        </>
    );

    const open = mainNavOpen || mainNavOpenAbsolute;

    if (isMobile) {
        return (
            <MuiDrawer
                open={open}
                onClose={() => setMainNavOpen(false)}
                sx={{ zIndex: theme.zIndex.drawer + 1 }}
            >
                <Box
                    role="presentation"
                    sx={{ p: theme.spacing(1), width: drawerWidth }}
                    flexGrow={1}
                    display="flex"
                    flexDirection="column"
                >
                    {mainNav()}
                </Box>
            </MuiDrawer>
        );
    }

    // Drawer when hover open & close (disable due to UX)
    // {...(!(mainNavOpen && !mainNavOpenAbsolute) && {
    //     onMouseOver: () => setMainNavOpen(true, true),
    //     onMouseLeave: () => setMainNavOpen(false),
    // })}

    return (
        <Drawer
            variant="permanent"
            open={open}
            onClose={() => setMainNavOpen(false)}
            openAbsolute={mainNavOpenAbsolute}
        >
            {mainNav()}
        </Drawer>
    );
}
